:root
{
	--red-color:rgb(228, 84, 84);
}



.canvasnode
{
	background-color: rgb(39, 39, 39);
	height: auto;
	width: auto;
	border: 1px solid black;
	position: absolute;
	top: 50px;
	left: 50px;
	/* transition: 0.15s; */
	border-radius: 5px;
	min-width: 280px;
	min-height: 100px;
	-webkit-transition: background-color 0.15s linear;
    -ms-transition: background-color 0.15s linear;
    transition: background-color 0.15s linear;
	
	font-size: medium;
	
	filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.25));
}

.canvasnode:hover { background-color: rgb(49, 49, 49); }
.canvasnode:active { background-color: rgb(54, 54, 54); }


.topbar
{
	background-color: rgb(10, 10, 10);
	height: auto;
}




.deletebutton
{
	height: 20px;
	width: 20px;
	display: block;
	background-color: var(--red-color);
	border-color: var(--red-color);
	margin-left: 5px;
	margin-right: 2px;


	background-image: url("../img/T_Delete.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.topbar .deletebutton
{
	margin-left: auto;
	background-image: none;
}

.premise
{
	display:flex;
	align-items: center;
	padding: 0px 5px 2px;
	padding-left: 0;
}

.wirebutton
{
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background-color: white;
	margin-right: 5px;
}

.refutationbutton
{
	width: 20px;
	height: 20px;
	background-color: var(--red-color);
	border-color: var(--red-color);
	border: no;
	margin: 3px;
	color: rgb(211, 31, 76);

	background-image: url("../img/T_Disagree.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

}

.dragbutton
{
	margin: 3px;
	background-color: inherit;
	color: white;
	width: 20px;
	height: 20px;
	border: none;

	background-image: url("../img/T_DragDrop.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

	opacity: 50%;
}

.premisenum
{
	margin: 3px;
	/* background-color: rgb(31, 31, 31); */
	color: white;

	text-align: center;
	user-select: none;
}

.nodetextarea
{
	min-height: 20px;
	height: 20px;
	resize: none;
	overflow: hidden;
	background-color: rgb(69, 69, 69);
	border-color: rgb(82, 82, 82);
	color: white;
}

.nodetextarea:disabled
{
	filter: opacity(0.5);
}

.subgraphButton
{
	width: 20px;
	height: 20px;
	background-color: rgb(51, 51, 51);
	margin-left: 5px;

	background-image: url("../img/T_Subgraph.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.addPremiseButton
{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	background-color: rgb(69, 69, 69);
	border-color: rgb(69, 69, 69);
}

.addPremiseButton .whitebox
{
	background-color: white;
	width: 20px;
	border-radius: 4px;
	margin-left: auto;
	margin-right: auto;
	font-weight: bold;
	font-size: large;
}

.addPremiseButton:hover { filter: brightness(150%); }

.successDiv
{
	background-color: rgb(38, 158, 62);
	height: 30px;
	margin: 5px 0px 5px 0px;
	transition: 0.25s;
}

.conclusionDiv
{
	background-color: rgb(161, 113, 23);
}

.conclusion
{
	display: flex;
	align-items: center;
	color: white;
	margin-left: 20px;
	user-select: none;
}

.conclusion > *
{
	margin: 5px;
}



.containernode
{
	/* width: 400px; */
	/* width: 100%; */
	/* padding-bottom: 30px; */
}
.dropzone
{
	background-color: rgb(128, 128, 128);
	position: relative;
	top: 20px;
	/* width: 100%; */
	left: 0;
	right: 0;
	/* height: 100%; */
	bottom: 0;
	
	text-align: center;
	font-size: 25px;
	color: rgb(89, 89, 89);
	
	user-select: none;
	
	min-height: 100px;
	
	/* padding: 10px; */
	
	/* height: 500px;
	width: 100%; */
}

.droppednode
{
	position: relative;
}

.hoveredDropzone
{
	background-color: rgb(211, 211, 211);
}

.nodetitle
{
	color: white;
	opacity: 0.25;
	user-select: none;
	position: absolute;
	top: 0;
	left: 5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 270px;
}